// 公司总部
<template>
  <div class="contactusUs-hq">
    <div class="hq-info">
      <p class="lxwm-label">联系方式</p>
      <Info />
    </div>

    <div class="hq-map">
      <div class="hq-map-title justify-between align-center">
        <div class="title-left align-center">
          <el-image :src="require('../../assets/images/lxwm-img2.png')"></el-image>
          <span>位置信息</span>
        </div>
        <div class="title-right">{{getConfigs.address}}</div>
      </div>

      <!-- 百度地图控件 -->
      <baidu-map class="bm-view" :center="location" :zoom="18">
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-marker :position="location" animation="BMAP_ANIMATION_BOUNCE"> </bm-marker>
      </baidu-map>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Info from '@/components/Info'
export default {
  name: 'Hq',
  components: {
    Info
  },
  data () {
    return {
      // 公司总部--经纬度
      location: { lng: 116.42828, lat: 39.894684 }
    }
  },
  computed: {
    ...mapGetters(['getConfigs']),
  },
};
</script>

<style scoped lang="less">
/deep/ .hq-info {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
  .lxwm-label {
    position: relative;
    line-height: 22px;
    margin-left: 35px;
    margin-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      left: -33px;
      width: 23px;
      height: 23px;
      background: url("../../assets/images/lxwm-img1.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.hq-map {
  border-radius: 20px;
  overflow: hidden;
  .hq-map-title {
    height: 77px;
    background-color: #fff;
    padding: 0 30px;
    .el-image {
      margin-right: 10px;
    }
  }
  .bm-view {
    width: 100%;
    height: 440px;
  }
}
</style>
